<template>
  <div>
    <div class="title">
      <div class="title_t">
        <span>合作服务商详情</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>

    <!--选项-->
    <div class="xuanxiang">
      <div class="xuanxiang_k" v-for="(item,index) in activeList" :key="index" :class="{xuanxiang_k1:index==isShow}" @click="xuanxiangclick(item,index)">
        <!--<img :src="item.img" alt="">-->
        <span>{{item.name}}</span>
      </div>
    </div>

    <!--基本信息 模块-->
    <!--<div v-if="isShow == 0">-->
    <!--  <el-form ref="form" :model="form" label-width="100px">-->
    <!--    &lt;!&ndash;甲方企业信息&ndash;&gt;-->
    <!--    <el-card shadow="never">-->
    <!--      <div style="border-bottom: 1px solid #F6F6F6;">-->
    <!--        <div>-->
    <!--          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;vertical-align: middle;"></span>-->
    <!--          <span style="font-size: 20px;font-weight: 700;color: #202033;vertical-align: middle;">甲方企业信息</span>-->
    <!--        </div>-->
    <!--        <div style="margin-top: 10px;">-->
    <!--          <el-row :gutter="15">-->
    <!--            <el-col :span="4">-->
    <!--              <img :src="form.first_party.business_license" alt="" style="height: 264px;width: 100%;">-->
    <!--            </el-col>-->
    <!--            <el-col :span="20">-->
    <!--              <el-row :gutter="15">-->
    <!--                <el-col :span="12">-->
    <!--                  <el-form-item label="企业名称">-->
    <!--                    <el-input-->
    <!--                        :disabled="true"-->
    <!--                        clearable-->
    <!--                        v-model="form.first_party.company_name"-->
    <!--                        placeholder="请输入企业名称"/>-->
    <!--                  </el-form-item>-->
    <!--                </el-col>-->
    <!--                <el-col :span="12">-->
    <!--                  <el-form-item label="信用代码">-->
    <!--                    <el-input-->
    <!--                        :disabled="true"-->
    <!--                        clearable-->
    <!--                        v-model="form.first_party.credit_code"-->
    <!--                        placeholder="请输入信用代码"/>-->
    <!--                  </el-form-item>-->
    <!--                </el-col>-->
    <!--              </el-row>-->

    <!--              <el-row :gutter="15">-->
    <!--                <el-col :span="12">-->
    <!--                  <el-form-item label="注册日期">-->
    <!--                    <el-date-picker-->
    <!--                        :disabled="true"-->
    <!--                        v-model="form.first_party.registration_date"-->
    <!--                        value-format="yyyy-MM-dd"-->
    <!--                        type="date"-->
    <!--                        placeholder="选择日期">-->
    <!--                    </el-date-picker>-->
    <!--                  </el-form-item>-->
    <!--                </el-col>-->
    <!--                <el-col :span="12">-->
    <!--                  <el-form-item label="注册资本">-->
    <!--                    <el-input-->
    <!--                        :disabled="true"-->
    <!--                        clearable-->
    <!--                        v-model="form.first_party.registered_capital"-->
    <!--                        placeholder="请输入注册资本"/>-->
    <!--                  </el-form-item>-->
    <!--                </el-col>-->
    <!--              </el-row>-->

    <!--              <el-row :gutter="15">-->
    <!--                <el-col :span="24">-->
    <!--                  <el-form-item label="注册地址">-->
    <!--                    <el-row :gutter="15">-->
    <!--                      <el-col :span="4">-->
    <!--                        <el-input-->
    <!--                          :disabled="true"-->
    <!--                          clearable-->
    <!--                          v-model="form.first_party.registration_place_name"-->
    <!--                          placeholder="注册地址"/>-->
    <!--                      </el-col>-->
    <!--                      <el-col :span="18">-->
    <!--                        <el-input-->
    <!--                            :disabled="true"-->
    <!--                            clearable-->
    <!--                            v-model="form.second_party.detailed_address"-->
    <!--                            placeholder="请输入详细地址 "/>-->
    <!--                      </el-col>-->
    <!--                    </el-row>-->
    <!--                  </el-form-item>-->
    <!--                </el-col>-->
    <!--              </el-row>-->

    <!--              <el-row :gutter="15">-->
    <!--                <el-col :span="12">-->
    <!--                  <el-form-item label="法人姓名">-->
    <!--                    <el-input-->
    <!--                        :disabled="true"-->
    <!--                        clearable-->
    <!--                        v-model="form.first_party.legal_person"-->
    <!--                        placeholder="请输入法人姓名"/>-->
    <!--                  </el-form-item>-->
    <!--                </el-col>-->
    <!--                <el-col :span="12">-->
    <!--                  <el-form-item label="法人身份证">-->
    <!--                    <el-input-->
    <!--                        :disabled="true"-->
    <!--                        clearable-->
    <!--                        v-model="form.first_party.corporate_identity_card"-->
    <!--                        placeholder="请输入法人身份证 "/>-->
    <!--                  </el-form-item>-->
    <!--                </el-col>-->
    <!--              </el-row>-->

    <!--              <el-row :gutter="15">-->
    <!--                <el-col :span="12">-->
    <!--                  <el-form-item label="法人身份证正面" label-width="110px">-->
    <!--                    <img :src="form.first_party.legal_person_identify_front" alt="" style="width: 210px;height: 140px;">-->
    <!--                  </el-form-item>-->
    <!--                </el-col>-->
    <!--                <el-col :span="12">-->
    <!--                  <el-form-item label="法人身份证反面" label-width="110px">-->
    <!--                    <img :src="form.first_party.legal_person_identify_reverse" alt="" style="width: 210px;height: 140px;">-->
    <!--                  </el-form-item>-->
    <!--                </el-col>-->
    <!--              </el-row>-->

    <!--            </el-col>-->
    <!--          </el-row>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div style="margin-top: 20px;">-->
    <!--        <div>-->
    <!--          <span style="width: 5px;height: 19px;background: #A3A4B2;display: inline-block;margin-right: 10px;vertical-align: middle;"></span>-->
    <!--          <span style="font-size: 20px;font-weight: 700;color: #202033;vertical-align: middle;">基本信息</span>-->
    <!--        </div>-->
    <!--        <div style="margin-top: 15px;">-->
    <!--          <el-row :gutter="15">-->
    <!--            <el-form-item label="企业logo">-->
    <!--              <div style="width: 110px;height: 110px;background: #feffff;border: 1px solid #e6e6f2;border-radius: 6px;">-->
    <!--                <img :src="form.first_party.logo" alt="" style="width: 100%;height: 100%">-->
    <!--              </div>-->
    <!--            </el-form-item>-->
    <!--          </el-row>-->

    <!--          <el-row :gutter="15">-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item label="服务商名称">-->
    <!--                <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.first_party.facilitator_name"-->
    <!--                    placeholder="请输入服务商名称 "/>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item label="机构代码">-->
    <!--                <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.first_party.institution_code"-->
    <!--                    placeholder="请输入机构代码 "/>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item label="服务商简称">-->
    <!--                <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.first_party.facilitator_abbreviation"-->
    <!--                    placeholder="请输入服务商简称 "/>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--          </el-row>-->

    <!--          <el-row :gutter="15">-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item label="详细地址">-->
    <!--                <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.first_party.profile_detailed_address"-->
    <!--                    placeholder="请输入详细地址 "/>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item label="负责人">-->
    <!--                <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.first_party.principal_name"-->
    <!--                    placeholder="请输入负责人 "/>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item label="负责人电话">-->
    <!--                <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.first_party.principal_phone"-->
    <!--                    placeholder="请输入负责人电话 "/>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--          </el-row>-->

    <!--          <el-row :gutter="15">-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item label="客服电话">-->
    <!--                <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.first_party.service_phone"-->
    <!--                    placeholder="请输入客服电话 "/>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item label="电子邮箱">-->
    <!--                <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.first_party.email_address"-->
    <!--                    placeholder="请输入电子邮箱 "/>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--          </el-row>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </el-card>-->


    <!--    &lt;!&ndash;已方企业信息&ndash;&gt;-->
    <!--    <el-card shadow="never">-->
    <!--        <div style="border-bottom: 1px solid #F6F6F6;">-->
    <!--          <div>-->
    <!--            <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;vertical-align: middle;"></span>-->
    <!--            <span style="font-size: 20px;font-weight: 700;color: #202033;vertical-align: middle;">已方企业信息</span>-->
    <!--          </div>-->
    <!--          <div style="margin-top: 10px;">-->
    <!--            <el-row :gutter="15">-->
    <!--              <el-col :span="4">-->
    <!--                <img :src="form.second_party.business_license" alt="" style="height: 264px;width: 100%;">-->
    <!--              </el-col>-->
    <!--              <el-col :span="20">-->
    <!--                <el-row :gutter="15">-->
    <!--                  <el-col :span="12">-->
    <!--                    <el-form-item label="企业名称">-->
    <!--                      <el-input-->
    <!--                        :disabled="true"-->
    <!--                        clearable-->
    <!--                        v-model="form.second_party.company_name"-->
    <!--                        placeholder="请输入企业名称"/>-->
    <!--                    </el-form-item>-->
    <!--                  </el-col>-->
    <!--                  <el-col :span="12">-->
    <!--                    <el-form-item label="信用代码">-->
    <!--                      <el-input-->
    <!--                        :disabled="true"-->
    <!--                        clearable-->
    <!--                        v-model="form.second_party.credit_code"-->
    <!--                        placeholder="请输入信用代码"/>-->
    <!--                    </el-form-item>-->
    <!--                  </el-col>-->
    <!--                </el-row>-->

    <!--                <el-row :gutter="15">-->
    <!--                  <el-col :span="12">-->
    <!--                    <el-form-item label="注册日期">-->
    <!--                      <el-date-picker-->
    <!--                        :disabled="true"-->
    <!--                        v-model="form.second_party.registration_date"-->
    <!--                        value-format="yyyy-MM-dd"-->
    <!--                        type="date"-->
    <!--                        placeholder="选择日期">-->
    <!--                      </el-date-picker>-->
    <!--                    </el-form-item>-->
    <!--                  </el-col>-->
    <!--                  <el-col :span="12">-->
    <!--                    <el-form-item label="注册资本">-->
    <!--                      <el-input-->
    <!--                        :disabled="true"-->
    <!--                        clearable-->
    <!--                        v-model="form.second_party.registered_capital"-->
    <!--                        placeholder="请输入注册资本"/>-->
    <!--                    </el-form-item>-->
    <!--                  </el-col>-->
    <!--                </el-row>-->

    <!--                <el-row :gutter="15">-->
    <!--                  <el-col :span="24">-->
    <!--                    <el-form-item label="注册地址">-->
    <!--                      <el-row :gutter="15">-->
    <!--                        <el-col :span="4">-->
    <!--                          <el-input-->
    <!--                            :disabled="true"-->
    <!--                            clearable-->
    <!--                            v-model="form.second_party.registration_place_name"-->
    <!--                            placeholder="注册地址"/>-->
    <!--                        </el-col>-->
    <!--                        <el-col :span="18">-->
    <!--                          <el-input-->
    <!--                            :disabled="true"-->
    <!--                            clearable-->
    <!--                            v-model="form.second_party.detailed_address"-->
    <!--                            placeholder="请输入详细地址 "/>-->
    <!--                        </el-col>-->
    <!--                      </el-row>-->
    <!--                    </el-form-item>-->
    <!--                  </el-col>-->
    <!--                </el-row>-->

    <!--                <el-row :gutter="15">-->
    <!--                  <el-col :span="12">-->
    <!--                    <el-form-item label="法人姓名">-->
    <!--                      <el-input-->
    <!--                        :disabled="true"-->
    <!--                        clearable-->
    <!--                        v-model="form.second_party.legal_person"-->
    <!--                        placeholder="请输入法人姓名"/>-->
    <!--                    </el-form-item>-->
    <!--                  </el-col>-->
    <!--                  <el-col :span="12">-->
    <!--                    <el-form-item label="法人身份证">-->
    <!--                      <el-input-->
    <!--                        :disabled="true"-->
    <!--                        clearable-->
    <!--                        v-model="form.second_party.corporate_identity_card"-->
    <!--                        placeholder="请输入法人身份证 "/>-->
    <!--                    </el-form-item>-->
    <!--                  </el-col>-->
    <!--                </el-row>-->

    <!--                <el-row :gutter="15">-->
    <!--                  <el-col :span="12">-->
    <!--                    <el-form-item label="法人身份证正面" label-width="110px">-->
    <!--                      <img :src="form.second_party.legal_person_identify_front" alt="" style="width: 210px;height: 140px;">-->
    <!--                    </el-form-item>-->
    <!--                  </el-col>-->
    <!--                  <el-col :span="12">-->
    <!--                    <el-form-item label="法人身份证反面" label-width="110px">-->
    <!--                      <img :src="form.second_party.legal_person_identify_reverse" alt="" style="width: 210px;height: 140px;">-->
    <!--                    </el-form-item>-->
    <!--                  </el-col>-->
    <!--                </el-row>-->

    <!--              </el-col>-->
    <!--            </el-row>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div style="margin-top: 20px;">-->
    <!--          <div>-->
    <!--            <span style="width: 5px;height: 19px;background: #A3A4B2;display: inline-block;margin-right: 10px;vertical-align: middle;"></span>-->
    <!--            <span style="font-size: 20px;font-weight: 700;color: #202033;vertical-align: middle;">基本信息</span>-->
    <!--          </div>-->
    <!--          <div style="margin-top: 15px;">-->
    <!--            <el-row :gutter="15">-->
    <!--              <el-form-item label="企业logo">-->
    <!--                <div style="width: 110px;height: 110px;background: #feffff;border: 1px solid #e6e6f2;border-radius: 6px;">-->
    <!--                  <img :src="form.second_party.logo" alt="" style="width: 100%;height: 100%">-->
    <!--                </div>-->
    <!--              </el-form-item>-->
    <!--            </el-row>-->

    <!--            <el-row :gutter="15">-->
    <!--              <el-col :span="8">-->
    <!--                <el-form-item label="服务商名称">-->
    <!--                  <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.second_party.facilitator_name"-->
    <!--                    placeholder="请输入服务商名称 "/>-->
    <!--                </el-form-item>-->
    <!--              </el-col>-->
    <!--              <el-col :span="8">-->
    <!--                <el-form-item label="机构代码">-->
    <!--                  <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.second_party.institution_code"-->
    <!--                    placeholder="请输入机构代码 "/>-->
    <!--                </el-form-item>-->
    <!--              </el-col>-->
    <!--              <el-col :span="8">-->
    <!--                <el-form-item label="服务商简称">-->
    <!--                  <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.second_party.facilitator_abbreviation"-->
    <!--                    placeholder="请输入服务商简称 "/>-->
    <!--                </el-form-item>-->
    <!--              </el-col>-->
    <!--            </el-row>-->

    <!--            <el-row :gutter="15">-->
    <!--              <el-col :span="8">-->
    <!--                <el-form-item label="详细地址">-->
    <!--                  <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.second_party.profile_detailed_address"-->
    <!--                    placeholder="请输入详细地址 "/>-->
    <!--                </el-form-item>-->
    <!--              </el-col>-->
    <!--              <el-col :span="8">-->
    <!--                <el-form-item label="负责人">-->
    <!--                  <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.second_party.principal_name"-->
    <!--                    placeholder="请输入负责人 "/>-->
    <!--                </el-form-item>-->
    <!--              </el-col>-->
    <!--              <el-col :span="8">-->
    <!--                <el-form-item label="负责人电话">-->
    <!--                  <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.second_party.principal_phone"-->
    <!--                    placeholder="请输入负责人电话 "/>-->
    <!--                </el-form-item>-->
    <!--              </el-col>-->
    <!--            </el-row>-->

    <!--            <el-row :gutter="15">-->
    <!--              <el-col :span="8">-->
    <!--                <el-form-item label="客服电话">-->
    <!--                  <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.second_party.service_phone"-->
    <!--                    placeholder="请输入客服电话 "/>-->
    <!--                </el-form-item>-->
    <!--              </el-col>-->
    <!--              <el-col :span="8">-->
    <!--                <el-form-item label="电子邮箱">-->
    <!--                  <el-input-->
    <!--                    :disabled="true"-->
    <!--                    clearable-->
    <!--                    v-model="form.second_party.email_address"-->
    <!--                    placeholder="请输入电子邮箱 "/>-->
    <!--                </el-form-item>-->
    <!--              </el-col>-->
    <!--            </el-row>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--    </el-card>-->

    <!--  </el-form>-->

    <!--  &lt;!&ndash;按钮&ndash;&gt;-->
    <!--  &lt;!&ndash;<div class="card_bottom">&ndash;&gt;-->
    <!--  &lt;!&ndash;  <el-button style="width: 200px !important;" @click="returnPage">取消</el-button>&ndash;&gt;-->
    <!--  &lt;!&ndash;  <el-button type="warning" style="width: 200px !important; margin-left: 20px;">提交</el-button>&ndash;&gt;-->
    <!--  &lt;!&ndash;</div>&ndash;&gt;-->
    <!--</div>-->

    <div v-if="isShow == 0">
      <el-form ref="form" :model="form" label-width="160px">
        <!--认证信息-->
        <el-card shadow="never">
          <div style="display: flex;align-items: center;">
            <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
            <span style="font-size: 20px;font-weight: 700;color: #202033;margin-right: 20px;">乙方企业信息</span>
          </div>
          <el-row :gutter="15" style="margin-top: 20px;">
            <el-col :span="4">
              <!--<div class="upload_bg_yingyezhizhao">-->
              <!--  <el-upload-->
              <!--    :action="action"-->
              <!--    :headers="headers"-->
              <!--    :on-success="successUpload"-->
              <!--    :limit="1"-->
              <!--    :file-list="fileList"-->
              <!--    :class="{hide_box: businessLicenseupload_btn}"-->
              <!--    :on-change="businessLicensechange"-->
              <!--    list-type="picture-card"-->
              <!--    :on-preview="businessLicensePreview"-->
              <!--    :on-remove="businessLicensehandleRemove">-->
              <!--    &lt;!&ndash;<i class="el-icon-plus"></i>&ndash;&gt;-->
              <!--    <img style="margin-top: 60px;" src="@/assets/images/home/upload_anniu.png" alt="">-->
              <!--    <div style="font-size: 16px;font-weight: 500;color: #ff9b05;margin-top: 15px;">上传营业执照</div>-->
              <!--  </el-upload>-->
              <!--  <el-dialog :visible.sync="businessLicensedialogVisible">-->
              <!--    <img width="100%" :src="businessLicensedialogImageUrl" alt="">-->
              <!--  </el-dialog>-->
              <!--</div>-->
              <img :src="form.second_party.business_license" alt="" style="height: 264px;width: 100%;">
            </el-col>
            <el-col :span="20">
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="企业名称" prop="company_name">
                    <el-input
                      :disabled="true"
                      clearable
                      v-model="form.second_party.company_name"
                      placeholder="请输入企业名称"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="信用代码" prop="credit_code">
                    <el-input
                      :disabled="true"
                      clearable
                      v-model="form.second_party.credit_code"
                      placeholder="请输入信用代码"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="注册日期" prop="registration_date">
                    <el-date-picker
                      :disabled="true"
                      v-model="form.second_party.registration_date"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="注册资本" prop="registered_capital">
                    <el-input
                      :disabled="true"
                      clearable
                      v-model="form.second_party.registered_capital"
                      placeholder="请输入注册资本 "/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="24">
                  <el-form-item label="注册地址" prop="registration_place_code">
                    <el-row :gutter="15">
                      <el-col :span="4">
                        <el-cascader
                          :disabled="true"
                          ref="refCascader"
                          :options="city"
                          v-model="form.second_party.registration_place_code"
                          ></el-cascader>
                      </el-col>
                      <el-col :span="18">
                        <el-input
                          :disabled="true"
                          clearable
                          v-model="form.second_party.detailed_address"
                          placeholder="请输入详细地址 "/>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="法人姓名" prop="legal_person">
                    <el-input
                      :disabled="true"
                      clearable
                      v-model="form.second_party.legal_person"
                      placeholder="请输入法人姓名"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="法人身份证" prop="corporate_identity_card">
                    <el-input
                      :disabled="true"
                      clearable
                      v-model="form.second_party.corporate_identity_card"
                      placeholder="请输入法人身份证 "/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <!--<el-col :span="12">-->
                <!--  <el-form-item label="法人身份证正面">-->
                <!--    &lt;!&ndash;<div class="upload_bg">&ndash;&gt;-->
                <!--    &lt;!&ndash;  <el-upload&ndash;&gt;-->
                <!--    &lt;!&ndash;    :action="action"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :headers="headers"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :on-success="successUpload1"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :limit="1"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :file-list="fileList1"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :class="{hide_box: upload_btn}"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :on-change="change"&ndash;&gt;-->
                <!--    &lt;!&ndash;    list-type="picture-card"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :on-preview="handlePictureCardPreview"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :on-remove="handleRemove">&ndash;&gt;-->
                <!--    &lt;!&ndash;    &lt;!&ndash;<i class="el-icon-plus"></i>&ndash;&gt;&ndash;&gt;-->
                <!--    &lt;!&ndash;    <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">&ndash;&gt;-->
                <!--    &lt;!&ndash;    <div style="font-size: 12px;font-weight: 500;color: #ff9b05;">上传身份证正面</div>&ndash;&gt;-->
                <!--    &lt;!&ndash;  </el-upload>&ndash;&gt;-->
                <!--    &lt;!&ndash;  <el-dialog :visible.sync="dialogVisible">&ndash;&gt;-->
                <!--    &lt;!&ndash;    <img width="100%" :src="dialogImageUrl" alt="">&ndash;&gt;-->
                <!--    &lt;!&ndash;  </el-dialog>&ndash;&gt;-->
                <!--    &lt;!&ndash;</div>&ndash;&gt;-->
                <!--    <img :src="form.second_party.legal_person_identify_front" alt="" style="width: 210px;height: 140px;">-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <!--<el-col :span="12">-->
                <!--  <el-form-item label="法人身份证反面">-->
                <!--    &lt;!&ndash;<div class="upload_bg_shenfenzheng">&ndash;&gt;-->
                <!--    &lt;!&ndash;  <el-upload&ndash;&gt;-->
                <!--    &lt;!&ndash;    :action="action"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :headers="headers"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :on-success="successUpload2"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :limit="1"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :file-list="fileList2"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :class="{hide_box: oppositeupload_btn}"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :on-change="oppositechange"&ndash;&gt;-->
                <!--    &lt;!&ndash;    list-type="picture-card"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :on-preview="oppositePreview"&ndash;&gt;-->
                <!--    &lt;!&ndash;    :on-remove="oppositehandleRemove">&ndash;&gt;-->
                <!--    &lt;!&ndash;    &lt;!&ndash;<i class="el-icon-plus"></i>&ndash;&gt;&ndash;&gt;-->
                <!--    &lt;!&ndash;    <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">&ndash;&gt;-->
                <!--    &lt;!&ndash;  </el-upload>&ndash;&gt;-->
                <!--    &lt;!&ndash;  <el-dialog :visible.sync="oppositedialogVisible">&ndash;&gt;-->
                <!--    &lt;!&ndash;    <img width="100%" :src="oppositedialogImageUrl" alt="">&ndash;&gt;-->
                <!--    &lt;!&ndash;  </el-dialog>&ndash;&gt;-->
                <!--    &lt;!&ndash;</div>&ndash;&gt;-->
                <!--    <img :src="form.second_party.legal_person_identify_reverse" alt="" style="width: 210px;height: 140px;">-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
              </el-row>
            </el-col>
          </el-row>
        </el-card>

        <!--基本信息-->
        <el-card shadow="never">
          <div>
            <div style="display: flex;align-items: center;">
              <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 20px;font-weight: 700;color: #202033;margin-right: 20px;">基本信息</span>
            </div>

            <el-row :gutter="15" style="margin-top: 20px;">
              <el-form-item label="企业logo" label-width="100px">
                <!--v-if="form.second_party.rank_type == 'teamwork'"-->
                <div class="upload_bg_logo" >
                  <el-upload
                    :action="action"
                    :headers="headers"
                    :on-success="successUpload3"
                    :limit="1"
                    :file-list="fileList3"
                    :class="{hide_box: logoupload_btn}"
                    :on-change="logochange"
                    list-type="picture-card"
                    :on-preview="logoPreview"
                    :on-remove="logohandleRemove">
                    <!--<i class="el-icon-plus"></i>-->
                    <img style="margin-top: 30px;width: 38px;height: 38px;" src="@/assets/images/home/upload_anniu.png" alt="">
                  </el-upload>
                  <el-dialog :visible.sync="logodialogVisible">
                    <img width="100%" :src="logodialogImageUrl" alt="">
                  </el-dialog>
                </div>
                <!--<img v-else :src="form.second_party.logo" alt="" style="width: 210px;height: 140px;">-->
              </el-form-item>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="8">
                <el-form-item label="服务商名称" prop="facilitator_name">
                  <el-input
                    :disabled="disabled"
                    clearable
                    v-model="form.second_party.facilitator_name"
                    placeholder="请输入服务商名称"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="机构代码">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.second_party.institution_code"
                    placeholder="请输入机构代码"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="服务商简称" prop="facilitator_abbreviation">
                  <el-input
                    :disabled="disabled"
                    clearable
                    v-model="form.second_party.facilitator_abbreviation"
                    placeholder="请输入服务商简称"/>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="8">
                <el-form-item label="详细地址" prop="profile_detailed_address">
                  <el-input
                    :disabled="disabled"
                    clearable
                    v-model="form.second_party.profile_detailed_address"
                    placeholder="请输入详细地址"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="负责人" prop="principal_name">
                  <el-input
                    :disabled="disabled"
                    clearable
                    v-model="form.second_party.principal_name"
                    placeholder="请输入负责人"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="负责人电话" prop="principal_phone">
                  <el-input
                    :disabled="disabled"
                    clearable
                    v-model="form.second_party.principal_phone"
                    placeholder="请输入负责人电话 "/>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="8">
                <el-form-item label="客服电话" prop="service_phone">
                  <el-input
                    :disabled="disabled"
                    clearable
                    v-model="form.second_party.service_phone"
                    placeholder="请输入客服电话"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="登录账号" prop="account">
                  <el-input
                    :disabled="disabled"
                    clearable
                    v-model="form.second_party.account"
                    placeholder="请输入登录账号"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="登录密码" prop="password">
                  <el-input
                    show-password
                    :disabled="disabled"
                    clearable
                    v-model="form.second_party.password"
                    placeholder="请输入登录密码"/>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="8">
                <el-form-item label="服务商等级" prop="dengji">
                  <el-select
                      clearable
                      v-model="form.dengji"
                      placeholder="请选择"
                      class="ele-fluid">
                    <el-option
                        v-for="(item) in dict.type.grade"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="外呼显示号码" prop="account">
                  <el-input
                      clearable
                      v-model="form.haoma"
                      placeholder="请输入"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="短信签名" prop="account">
                  <el-input
                      clearable
                      v-model="form.waihu"
                      placeholder="请输入"/>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="8">
                <el-form-item label="版权" prop="account">
                  <el-input
                      clearable
                      v-model="form.banquan"
                      placeholder="请输入"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="二级域名" prop="account">
                  <el-input
                      clearable
                      v-model="form.erji"
                      placeholder="请输入"/>
                </el-form-item>
              </el-col>
            </el-row>

          </div>
        </el-card>
      </el-form>
      <!--按钮-->
      <div class="card_bottom" v-if="disabled == false">
        <el-button style="width: 200px !important;" @click="returnPage">取消</el-button>
        <el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="save('form')" v-auths="[`${$config.uniquePrefix}yunli:serviceProvidersAndServiceProviders:put`]">提交</el-button>
      </div>
    </div>

    <!--计价公式 模块-->
    <div class="Pricingformula" v-if='isShow == 1'>
      <div>
        <Pricingformula :id="data.second_facilitator_id"></Pricingformula>
      </div>
    </div>

    <!--对账资料 模块-->
    <div class="Addressbook" v-if='isShow == 2'>
      <div>
        <ReconciliationInformation :id="data.second_facilitator_id"></ReconciliationInformation>
      </div>
    </div>

    <!--合同信息 模块-->
    <div class="Addressbook" v-if='isShow == 3'>
      <div>
        <ContractInfo :id="data.second_facilitator_id" :dict="dict"></ContractInfo>
      </div>
    </div>

    <!--聚合 模块-->
    <div class="Addressbook" v-if='isShow == 4'>
      <div>
            <ServiceConfiguration :id="data.second_facilitator_id" :dict="dict"></ServiceConfiguration>
      </div>
    </div>

  </div>
</template>

<script>
// 引入计价公式组件
import Pricingformula from './components/Pricingformula.vue'
// 引入对账资料组件
import ReconciliationInformation from './components/reconciliationInformation.vue'
// // 引入服务配置组件
import ServiceConfiguration from './components/ServiceConfiguration.vue'
// // 引入合同信息组件
import ContractInfo from './components/ContractInfo.vue'

// 引入的配置
import Vue from "vue";

// 引入的接口
import {
  Detailsfacilitators,
  facilitatorsecond_party_info,
  getcityList,
  update_profile,
} from "@/api/yunli";

// 获取配置
import setting from "@/config/setting";
import {getCookies} from "@/utils/cookies";
import Config from "@/config";



export default {
  name: 'index',
  // 支柱道具属性
  props: {
    //当前数据
    data:Object,
    dict: Object,
  },
  // 组件生效
  components: {
    Pricingformula,
    ReconciliationInformation,
    ServiceConfiguration,
    ContractInfo,
  },
  // Data数据
  data() {
    return {
      form:{},

      isShow:0,
      activeList:[
        {
          // img: require('../../../../assets/images/home/custom/icon1active.png'),
          name:'基本信息',
        },
        {
          // img: require('../../../../assets/images/home/custom/icon2.png'),
          name:'计价公式',
        },
        {
          // img: require('../../../../assets/images/home/yunli/icon3.png'),
          name:'对账资料',
        },
        // {
        //   img: require('../../../../assets/images/home/custom/icon4.png'),
        //   name:'服务配置',
        // },
        {
          // img: require('../../../../assets/images/home/custom/icon5.png'),
          name:'合同信息',
        },
        {
          name:'聚合配置'
        }
      ],


      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      //营业执照
      businessLicenseupload_btn:false,
      businessLicensedialogVisible:false,
      businessLicensedialogImageUrl:'',
      fileList:[],

      // 身份证正面
      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList1:[],

      //身份证反面
      oppositedialogImageUrl:'',
      oppositedialogVisible:false,
      oppositeupload_btn:false,
      fileList2:[],

      //企业logo
      logoupload_btn:false,
      logodialogVisible:false,
      logodialogImageUrl:'',
      fileList3:[],

      //登录页背景图
      bgupload_btn:false,
      bgdialogVisible:false,
      bgdialogImageUrl:'',
      fileList4:[],

      //字典
      dict: {
        type:{}
      },

      city:[],

      disabled:false,


    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '客户字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    this.getCity();

    // 调用获取详情
    this.getDetails();

  },
  // 方法集合
  methods: {
    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    //获取详情
    getDetails(){
      console.log(this.data,'data')
      //   facilitatorsecond_party_info
      facilitatorsecond_party_info(this.data.id).then(res => {
        this.form = res.data;
        // 企业logo
        if(this.form.second_party.logo != ''){
          let data3 = {
            url: this.form.second_party.logo
          }
          this.logoupload_btn = true;
          this.fileList3.push(data3);
        }

        // if(this.form.second_party.rank_type == 'teamwork'){
        //   this.disabled = false;
        // }else {
        //   this.disabled = true;
        // }

      })
    },

    // 选项卡点击事件
    xuanxiangclick(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      // if(item.name == '基本信息'){
      //   this.activeList[0].img = require('../../../../assets/images/home/custom/icon1active.png');
      //   this.activeList[1].img = require('../../../../assets/images/home/custom/icon2.png');
      //   this.activeList[2].img = require('../../../../assets/images/home/yunli/icon3.png');
      //   // this.activeList[3].img = require('../../../../assets/images/home/custom/icon4.png');
      //   this.activeList[3].img = require('../../../../assets/images/home/custom/icon5.png');
      // }else if(item.name == '计价公式'){
      //   this.activeList[0].img = require('../../../../assets/images/home/custom/icon1.png');
      //   this.activeList[1].img = require('../../../../assets/images/home/custom/icon2active.png');
      //   this.activeList[2].img = require('../../../../assets/images/home/yunli/icon3.png');
      //   // this.activeList[3].img = require('../../../../assets/images/home/custom/icon4.png');
      //   this.activeList[3].img = require('../../../../assets/images/home/custom/icon5.png');
      // }else if(item.name == '对账资料'){
      //   this.activeList[0].img = require('../../../../assets/images/home/custom/icon1.png');
      //   this.activeList[1].img = require('../../../../assets/images/home/custom/icon2.png');
      //   this.activeList[2].img = require('../../../../assets/images/home/yunli/icon3active.png');
      //   // this.activeList[3].img = require('../../../../assets/images/home/custom/icon4.png');
      //   this.activeList[3].img = require('../../../../assets/images/home/custom/icon5.png');
      // }
      // // else if(item.name == '服务配置'){
      // //   this.activeList[0].img = require('../../../../assets/images/home/custom/icon1.png');
      // //   this.activeList[1].img = require('../../../../assets/images/home/custom/icon2.png');
      // //   this.activeList[2].img = require('../../../../assets/images/home/yunli/icon3.png');
      // //   this.activeList[3].img = require('../../../../assets/images/home/custom/icon4active.png');
      // //   this.activeList[4].img = require('../../../../assets/images/home/custom/icon5.png');
      // // }
      //
      // else if(item.name == '合同信息'){
      //   this.activeList[0].img = require('../../../../assets/images/home/custom/icon1.png');
      //   this.activeList[1].img = require('../../../../assets/images/home/custom/icon2.png');
      //   this.activeList[2].img = require('../../../../assets/images/home/yunli/icon3.png');
      //   // this.activeList[3].img = require('../../../../assets/images/home/custom/icon4.png');
      //   this.activeList[3].img = require('../../../../assets/images/home/custom/icon5active.png');
      // }
    },

    // 返回上级页面
    returnPage(){
      // this.$router.go(-1);
      this.$emit('showDetalis',false) ;
    },

    // 营业执照
    businessLicensePreview(file){
      this.businessLicensedialogImageUrl = file.url;
      this.businessLicensedialogVisible = true;
    },
    businessLicensehandleRemove(file,fileList){
      console.log(file, fileList);
      this.businessLicenseupload_btn = false;
    },
    businessLicensechange(){
      this.businessLicenseupload_btn = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.businessLicensedialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.business_license = file.data.urls;
    },


    //身份证正面
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    change(){
      this.upload_btn = true;
    },
    //上传成功
    successUpload1(file,fileList){
      console.log(file);
      console.log(fileList)
      this.dialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.legal_person_identify_front = file.data.urls;
    },

    //身份证反面
    oppositePreview(file){
      this.oppositedialogImageUrl = file.url;
      this.oppositedialogVisible = true;
    },
    oppositehandleRemove(file,fileList){
      this.oppositeupload_btn = false;
    },
    oppositechange(){
      this.oppositeupload_btn = true;
    },
    successUpload2(file,fileList){
      console.log(file);
      console.log(fileList)
      this.oppositedialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.legal_person_identify_reverse = file.data.urls;
    },

    //企业logo
    logoPreview(file){
      this.logodialogImageUrl = file.url;
      this.logodialogVisible = true;
    },
    logohandleRemove(file,fileList){
      this.logoupload_btn = false;
    },
    logochange(){
      this.logoupload_btn = true;
    },
    successUpload3(file,fileList){
      console.log(file);
      console.log(fileList)
      this.logodialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.second_party.logo = file.data.urls;
    },

    //登录页背景图
    bgPreview(file){
      this.bgdialogImageUrl = file.url;
      this.bgdialogVisible = true;
    },
    bghandleRemove(file,fileList){
      this.bgupload_btn = false;
    },
    bgchange(){
      this.bgupload_btn = true;
    },
    successUpload4(file,fileList){
      console.log(file);
      console.log(fileList)
      this.bgdialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.login_background = file.data.urls;
    },

    //提交
    save(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            logo: this.form.second_party.logo,
            facilitator_name: this.form.second_party.facilitator_name,
            facilitator_abbreviation: this.form.second_party.facilitator_abbreviation,
            profile_detailed_address:this.form.second_party.profile_detailed_address,
            principal_name:this.form.second_party.principal_name,
            principal_phone:this.form.second_party.principal_phone,
            service_phone:this.form.second_party.service_phone,
            email_address:this.form.second_party.email_address,
            account:this.form.second_party.account,
            password:this.form.second_party.password,
          }
          //编辑
          update_profile(this.data.id,data).then(res => {
            console.log(res)
            if (res.code === 200) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.$message.error(e.msg);
          })
        } else {
          return false;
        }
      });
    },

  },
  // 生命周期 - 更新之前
  beforeUpdate() {
  },
  // 生命周期 - 更新之后
  updated() {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {
  },
  // 生命周期 - 挂载之前
  beforeMount() {
  },
  // 生命周期 - 销毁之前
  beforeDestroy() {
  },
  // 生命周期 - 销毁完成
  destroyed() {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated() {
  },
  // 离开的时候触发
  deactivated() {
  }
}
</script>

<style scoped lang="scss">
.card{
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  .card_t{
    display: flex;
    align-items: center;
  }
  .card_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .card_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.card_bottom{
  margin-top: 80px;
  text-align: center;
}

.condition{
  .condition_t{
    display: flex;
    align-items: center;
  }
  .condition_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }

  .condition_b{
    margin-top: 20px;
    .condition_b_img{
      width: 280px;
      height: 255px;
      background-image: url("../../../../assets/images/home/custom/upload_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      padding-top: 50px;
      cursor: pointer;
    }
    .condition_b_img_anniu{
      width: 94px;
      height: 94px;
    }
    .condition_b_img_text{
      font-size: 16px;
      font-weight: 500;
      color: #ff9b05;
    }
    .condition_b_l{
      width: 15%;
      float: left;
    }
    .condition_b_r{
      width: 76%;
      float: right;
      margin-left: 30px;
    }
  }
  // 解决高度坍塌
  .condition_b:after{
    content: '';
    clear: both;
    display: block;
  }
}

.xuanxiang{
  cursor: pointer;
  display: flex;
  .xuanxiang_k{
    width: 150px;
    height: 50px;
    background: #e8e8e8;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border-top-right-radius: 30px;
    img{
      width: 20px;
      height: 20px;
    }
    span{
      font-size: 20px;
      font-weight: 700;
      color: #7b7b7b;
      margin-left: 5px;
    }
  }
  .xuanxiang_k1{
    width: 150px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border-top-right-radius: 30px;
    img{
      width: 20px;
      height: 20px;
    }
    span{
      font-size: 20px;
      font-weight: 700;
      color: #FF9B05;
      margin-left: 5px;
    }
  }
}

.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
